.ant-menu-item {
  padding: 10px 0px 0px 0px;
  display: 'inline-block';
}

.ant-menu {
  height: 70px;
}

.logo-item {
  width: 10px;
  padding: 0px 15px 0px 0px;
  cursor: default;
}

.ant-menu-item:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.header-desktop-div {
  display: block;  
  background: black;
  padding: 0.5rem
}

.header-desktop-div {
  display: none
}


/* mobile */
@media screen and (max-width: 767px) {
  .header-desktop-div {
    display: none
  }    

  .header-mobile-div {
    display: block
  }  
}

@media (min-width: 768px) {
  .header-desktop-div {
    display: block
  }    

  .header-mobile-div {
    display: none
  }  
}
 
