.signup-page {
  height: 100vh;
  background-color: #f0f2f5;
}

.signup-page__form-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
}

.signup-page__title {
  text-align: center;
  margin-bottom: 24px;
}

.signup-page__submit-button {
  width: 100%;
}

.signup-page__login-link {
  margin-top: 16px;
  text-align: center;
}
