.row-wrapper {
  position: relative;
}

.feature-block {
     display: inline-block;
    padding: 48px 24px;
    vertical-align: top  
}

.feature-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
  transform: translate(0px, 0px); 
  opacity: 1;  
}