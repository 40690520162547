.banner {
  position: relative;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('../images/home-screen.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.banner2 {
  position: relative;
  background-color: black;
  background-repeat: no-repeat;
  padding-bottom: 4rem
}

.banner2 img {
  width: 75%;
  height: 100%;
  display: block;
}

.banner2-text {
  padding: 1em 1rem 4rem 1rem;
  text-align: center
}


.hero-font-title {
  margin-bottom: 1.25rem;
  font-weight: 900;
  font-family: AliPuHui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  word-wrap: break-word;
  min-height: 1.5rem;
  color: white;
  font-size: 1.5rem;
  padding: 10rem 1rem 0 1rem;
}

.hero-font-content {
  margin-bottom: 1.25rem;
  font-weight: 900;
  word-wrap: break-word;
  min-height: 1.5rem;
  color: white;
  font-size: 1.2rem;
  font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.text-padding {
  padding: 0 1rem 0 1rem
}

.hero-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-family: AliPuHui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  height: auto;
}

.member-text {
  font-size: 1rem;
  color: white;
  padding-top: 1.5rem;
  position: relative;
}

.dell,
.fidelity,
.accenture,
.td-security,
.irish-life {
  max-width: 100%;
  max-height: 2rem;
}

.company-list {
  padding: 12rem 1rem 0rem 1rem;
}

@media screen and (max-width: 374px) {
  .hero-button {
    white-space: normal;
    max-width: 13rem;
  }
  .hero-font-title {
    padding: 3rem 1rem 0 1rem;
  }  
  .company-list {
    padding: 0rem 1rem 0rem 1rem;
  }   
}

@media screen and (min-width: 375px) and (max-width: 576px) {
  .hero-font-title {
    padding: 3rem 1rem 0 1rem;
  }  
  .company-list {
    padding: 3rem 1rem 0rem 1rem;
  } 
}

@media screen and (min-width: 577px) and (max-width: 992px){
  .hero-font-title {
    padding: 3rem 1rem 0 1rem;
  }  
  .company-list {
    padding: 3rem 1rem 0rem 1rem;
  } 
}

@media screen and (min-width: 993px) and (max-width: 1494px){
  .hero-font-title {
    padding: 1rem 1rem 0 1rem;
  }  
}

@media screen and (min-width: 1495px){
  .hero-font-title {
    font-size: 2.5rem;
  }

  .hero-font-content {
    font-size: 1.8rem;
  }

  .hero-button {
    font-size: 1.3rem;
  }

  .member-text {
    font-size: 1.5rem;
  }

  .dell,
  .fidelity,
  .accenture,
  .td-security,
  .irish-life {
    max-height: 3rem;
  }
}


/* @media screen and (min-width: 1024px) and (max-width: 1279px) {
  .banner {
    height: 55vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .banner {
    height: 32vh;
  }
}

@media screen and (min-width: 541px) and (max-width: 767px) {
  .banner {
    height: 45vh;
  }
}

@media screen and (min-width: 390px) and (max-width: 540px) {
  .banner {
    height: 55vh;
  }
}

@media screen and (min-width: 281px) and (max-width: 389px) {
  .banner {
    height: 60vh;
  }
}*/

