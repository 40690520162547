.footer-container {
    background-color: #f5f5f5;
    color: black;
    padding: 50px 0;
  }
  
  .logo-row {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;      
  }
  
  .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  
  