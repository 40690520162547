.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.profile-form {
  width: 80%;
  padding: 10px
}

.profile-tag {
    border-radius: 10px;
    margin: 8px;
    padding: 8px 16px;
    cursor: pointer;
    white-space: break-spaces;
    word-break: break-word;  
}