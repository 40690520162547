.whitepaper-container {
  padding-top: 3rem;
}

.whitepaper-photo {
    background-image: url('../images/whitepaper-2024.png');
    background-repeat: no-repeat;
    background-size: 65%;
    height: 35vh;
    background-position: center;
}

.whitepaper-photo-2023 {
  background-image: url('../images/whitepaper-2023.png');
  background-repeat: no-repeat;
  background-size: 65%;
  height: 35vh;
  background-position: center;
}

.whitepaper-photo-tab {
  background-image: url('../images/whitepaper-2024.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 90vh
}

.whitepaper-download-btn {
  height: auto; /* Adjust based on your needs */
  font-family: AliPuHui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  font-size: 1.2rem;
}

.whitepaper-big-text {
  margin-top: 5rem;
}

.whitepaper-text-desc {
  font-size: 1.1rem;
  padding: 0rem 1rem 1rem 1rem
}

.previous-whitepaper-heading {
  font-size: 1.1rem;
  margin-top: 5rem;
  margin-left: 10rem;
}

@media screen and (max-width: 280px) {
  .whitepaper-photo-tab {
    height: 60vh
  }

  .whitepaper-download-btn{
    white-space: normal;
    max-width: 15rem;
  }
  .previous-whitepaper-heading {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 281px) and (max-width: 539px) {
  .whitepaper-photo-tab {
    height: 60vh
  }
  .whitepaper-download-btn{
    white-space: normal;
    max-width: 15rem;
  }
  .previous-whitepaper-heading {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 539px){
  .whitepaper-photo {
    background-size: 65%;
    height: 50vh;
  }  
  .whitepaper-big-text {
    margin-top: 1rem;
  }   
  .previous-whitepaper-heading {
    margin-left: 2rem;
  } 
}

@media screen and (min-width: 540px) and (max-width:1024px){
  .whitepaper-photo {
    background-size: 65%;
    height: 70vh;
  }  
  .whitepaper-big-text {
    margin-top: 1rem;
  }    
  .previous-whitepaper-heading {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-height: 600px){
  .whitepaper-photo {
    background-size: 60%;
    height: 150vh;
  }
  .whitepaper-photo-tab {
    height: 200vh
  }
  .previous-whitepaper-heading {
    margin-left: 2rem;
  }
}