.ant-btn-primary {
    background-color: #4e98d1;
    /* border-color: blue; */
    color: #fff;
  }
  
.menu-text {
    font-style: normal;
    font-size: 22px;
}

.icon-menu {
    font-size: 50px;
}

.section-title {
    font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-weight: 900;
    font-size: 30px;
}

.card-title {
    font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-size: 20px;
}

.card-title:hover {
    color: grey;
    cursor: pointer

}

.card-description {
    font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.card-photo {
    margin: 0 auto; /* set horizontal margin to auto */
    display: block; /* make sure the div is a block-level element */
    text-align: center; /* center the contents of the div */
}

.card-publish-date {
    font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    
}

.card-publisher {
    font-family: AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    color: black;
    padding: 5px 0 5px 0

}

.card-publisher:hover {
    cursor: pointer;
}

.about {
    text-align: 'center'
}

.data-card  {
    width: 100%;
    background-color: #e6f7ff;
    min-width: 12.5rem;
    transition: box-shadow 0.3s ease
}

.data-card:hover {
    box-shadow: 0 0 10px white;
  }

.data-card .ant-card-cover {
    height: 12rem;
    display: flex;
}

.data-card .ant-card-cover img {
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 1023px) {
    .section-title {
        font-size: 20px;
        padding-left: 10px
    }    
}
  
.user-profile {
    font-size: "18px"
}

.ai-menu-button {
    color: rgba(255, 255, 255, 0.65);
    font-size: 22px;
    font-weight: bold;
    padding: 15px;
    display: inline-block;
}

.ai-menu-button:hover {
    color: white;
    cursor: pointer;
}

.personalization {
    display: inline-block;
    background-color: #1890ff;
    border-radius: 10px;
    padding: 8px;
}

.personalization:hover {
    cursor: pointer;
}

.ai-menu-row {
    background-color: black;
}

.break-word-item {
    white-space: "break-spaces";
    word-break: "break-word";             
}

.ant-table-thead .ant-table-cell {
    background-color: white !important;
    }
  
  
.long-title-divider {
    white-space: nowrap;
}

@media (max-width: 600px) {
    .long-title-divider {
        font-size: 0.8rem
    }
}

.title-span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 4.2em;
}

@media (max-width: 1179px) {
    .title-span {
        font-size: 0.9rem
    }
}

.tag-news-card {
    position: absolute;
    top: 8px;
    right: 0px;
    background-color:   #1890ff;
    color: #FFFFFF;
    border-color: transparent;
}
    
.tag-card-details {
    position: absolute;    
    background-color: red;
    color: #FFFFFF;
    top: 8px;
    right: 0px;
}

.sentiment-icon {
    font-size: 32px
}

.value-member {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.intro-video {
    align-items: center;
    justify-content: center;
    display: flex
}    

@media screen and (max-width: 972px){
    .intro-video {
        align-items: center;
        justify-content: center;
        display: flex    
    }       
}



/* Center the contents of the bubble chart */
.bubble-chart-container {
  flex: 1;
  position: relative;
  display: flex;
  user-select: none;
  justify-content: center;
}

/* Center text in the circle, and hide any overflow */
.bubble-chart-container .bubble-chart-text .bubble-label {
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  will-change: left, top, height, width;
}

/* Show the overflow when hovering over a label */
.bubble-chart-container .bubble-chart-text .bubble-label:hover {
  overflow: visible;
  font-weight: bold;
}

/* Optional, but recommended - reduce the size of text on medium bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.medium {
  font-size: 0.8em;
}

/* Optional, but recommended - hide the text on small bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.small {
  font-size: 0;
}
/* Make sure to unhide the text when hovering */
.bubble-chart-container .bubble-chart-text .bubble-label.small:hover {
  font-size: 0.8em;
}

.search-collapse  {
    width: 75%;
    background-color: #4e98d1;
    border: #4e98d1
}
.search-collapse .ant-collapse-arrow {
    display: none !important;
    background-color: #4e98d1
}
/* myComponent.css */

.myBubbleUI {
	width: 100%;
	max-width: 1000px;
	height: 500px;
	border-radius: 50px
}

.child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.analytics-area {
    padding: 2rem 0 0 0
}

.blue-button:hover {
    cursor: pointer;
}

.red-button:hover {
    cursor: pointer;
}

.control-button {
    color:white;
    line-height: 1.25rem
}

.control-button:hover {
    cursor: pointer;
}

.search-box {
    padding: 0 0 2rem 0;
}

.news-pagination {
    background: white;
    padding: 0.2rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.4rem;
}

.chart-desktop {
    display: flex
}

.chart-mobile {
    display: none
}

.chart-mobile-btn {
    font-size: 3rem
}

.chart-mobile-btn:hover {
    cursor: pointer;
    color: blue
}

@media screen and (max-width: 911px) {
    .chart-desktop {
        display: none
    }
    
    .chart-mobile {
        display: flex
    }
}
  

   
