.signin-page {
  height: 100vh;
  background-color: #f0f2f5;
}

.signin-page__form-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
}

.signin-page__title {
  text-align: center;
  /* margin-bottom: 24px; */
}

.signin-page__submit-button {
  width: 100%;
}

.signin-page__login-link {
  margin-top: 5px;
  text-align: center;
}
